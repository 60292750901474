














import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Login from "@/views/Login.vue";


@Component({
  components: {
    Header,
    Login,
  },
})
export default class App extends Vue {

  private get harRolle():boolean {
    const rolle = this.$store.state.rolle
    return rolle === 'GUEST' || rolle === 'ADMIN';
  }

}
