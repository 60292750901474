import axios, { AxiosRequestConfig } from 'axios';
import store from '@/store';

const host = location.host.includes('localhost') ? 'http://' + location.host : 'https://' + location.host

export class HttpService {
    private requestConf(): AxiosRequestConfig {
        const headers: Record<string, string> = {
            'x-kodeord': store.state.kodeord,
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache'
        };
        return {
          headers
        };
      }

    public async get(url: string) {
        try {
            const res = await axios.get(host + url, this.requestConf()).catch(error => {
                console.log(error.response.status)
                if (error.response.status == 401) {
                    store.commit('opdaterRolle', 'INGEN')
                    store.commit('saetKodeord', '')
                }
            });
            return res
        } catch (err) {
            return err;
        }
    }
}

export const httpService = new HttpService();
