

















































































import { Component, Vue } from "vue-property-decorator"
import * as DKFDS from "dkfds";

@Component({})
export default class Header extends Vue {
    public async mounted() {
        DKFDS.init()
    }
}
