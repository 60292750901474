import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Program",
    component: () =>
      import(/* webpackChunkName: "Program" */ "../views/Program.vue"),
  },
  {
    path: "/historik",
    name: "Historisk program",
    component: () =>
      import("../views/Program.vue")
  }
];

const router = new VueRouter({
  routes,
});

export default router;