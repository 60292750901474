import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  state: {
    kodeord: '',
    rolle: 'INGEN'
  },
  mutations: {
    opdaterRolle (state, nyrolle) {
      state.rolle = nyrolle
    },
    saetKodeord (state, nytkodeord) {
      state.kodeord = nytkodeord
    }
  }
});