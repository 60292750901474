



























import { Component, Vue } from "vue-property-decorator";
import { httpService } from '../service/http.service';

@Component({})
export default class Login extends Vue {
    private kodeord = ''
    public async logind() {
        this.$store.commit('saetKodeord', this.kodeord);

        const rolle = await httpService.get('/api/auth?kodeord=' + this.kodeord);
        this.$store.commit('opdaterRolle', rolle.data);
    }
}
